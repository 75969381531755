import initialState from "../../../store/initialState";
import {
  GET_PANEL_DETAILS_STARTING,
  GET_PANEL_DETAILS_SUCCESS,
  GET_PANEL_DETAILS_ERROR,
  ADD_PANEL_DETAILS_SUCCESS,
  UPDATE_PANEL_DETAILS_SUCCESS,
  DEACTIVATE_PANEL_DETAILS_SUCCESS,
} from "../../../constants/actionTypes";
import { cloneDeep, initial } from "lodash";

export default function panelDetailsReducer(state = initialState.panelDetails,action) {
  
  const sortByOrder = (newState) => {};
  switch (action.type) {
    case GET_PANEL_DETAILS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      return newState;
    }

    case GET_PANEL_DETAILS_SUCCESS: {
      const newState = cloneDeep(state);
      // console.log('state', state)
      // console.log('newState',newState )
      const ids = [];
      // console.log('ids',ids )
      action.data.panels.forEach((panelDetail) => {
        // console.log('panelDetail',panelDetail )
        const panelId = panelDetail.id;
        ids.push(panelId);
        newState.map[panelId] = panelDetail;

      });
      newState.ids = ids;
      newState.loading = false;
      newState.loaded = true;
      sortByOrder(newState);
      return newState;
    }

    case ADD_PANEL_DETAILS_SUCCESS: {
      const newState = cloneDeep(state);
      const { panel } = action.data;
      const panelId = panel.id;

      newState.map[panelId] = panel;
      newState.ids.push(panelId);

      newState.ids.sort((ten1Id, ten2Id) => {
          const ten1 = newState.map[ten1Id];
          const ten2 = newState.map[ten2Id];
          return ten1.id - ten2.id;
      });

      return newState;
  }

  case UPDATE_PANEL_DETAILS_SUCCESS: {
    const newState = cloneDeep(state);
    const {panel} = action.data;
    const panelId = panel.id;
    newState.map[panelId] = panel;

    sortByOrder(newState);
    return newState;
  }

  case DEACTIVATE_PANEL_DETAILS_SUCCESS: {
    const {panelId} = action;
    const newState = cloneDeep(state);

    newState.ids = newState.ids.filter((pnlId) => {
      return panelId !== pnlId;
  });

    return newState;
  }

    case GET_PANEL_DETAILS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      return newState;
    }
    default: {
      return state;
    }
  }
}
